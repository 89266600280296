export const addNotUniqueVisit = () => {
    return () => {
        fetch('/api/visit/addvisit', {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
        })
            .catch(err => console.log(err));
    }
};
