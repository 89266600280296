import React from "react";
import commonStyle from "../Common/Style.module.scss";

export class Restrict extends React.Component {
  render() {
    return <div className={commonStyle.titlePageText}>Access restricted</div>;
  }
}

export default Restrict;
