export const isExpert = (bool) => {
  return {
    type: "IS_EXPERT",
    payload: bool,
  };
};

export const chekExpAcc = () => {
  return (dispatch) => {
    fetch("/api/isqea/isexpert", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("user_token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((expert) => {
        if (expert.message !== "Access Denied")
          dispatch(isExpert(expert.result));
        else {
          dispatch(isExpert(false));
        }
      });
  };
};

export const isExpertInFact = (bool) => {
  return {
    type: "IS_EXPERT_IN_FACT",
    payload: bool,
  };
};

export const checkExpInFac = () => {
  return (dispatch) => {
    fetch("/api/isqea/isexpertfact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("user_token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((expert) => {
        if (expert.message !== "Access Denied")
          dispatch(isExpertInFact(expert.result));
        else {
          dispatch(isExpertInFact(false));
        }
      });
  };
};

export const isQuerist = (bool) => {
  return {
    type: "IS_QUERIST",
    payload: bool,
  };
};

export const chekQueryAcc = () => {
  return (dispatch) => {
    fetch("/api/isqea/isquerist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("user_token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((query) => {
        if (query.message !== "Access Denied")
          dispatch(isQuerist(query.result));
        else {
          dispatch(isQuerist(false));
        }
      });
  };
};

export const isAdmin = (bool) => {
  return {
    type: "IS_ADMIN",
    payload: bool,
  };
};

export const chekAdminAcc = () => {
  return (dispatch) => {
    fetch("/api/isqea/isadmin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("user_token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((admin) => {
        if (admin.message !== "Access Denied") dispatch(isAdmin(admin.result));
        else {
          dispatch(isAdmin(false));
        }
      });
  };
};

export const chekHTTPS = () => {
  return () => {
    if (
      window.location.protocol !== "https:" &&
      window.location.hostname !== "localhost"
    ) {
      window.location.replace(
        `https:${window.location.href.substring(
          window.location.protocol.length
        )}`
      );
    }
  };
};
