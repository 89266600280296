import React, { Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  chekAdminAcc,
  chekQueryAcc,
  chekExpAcc,
  checkExpInFac,
  chekHTTPS,
} from "./actions/isQEA/checkIs";

import s from "./App.module.css";

import { ToProtect } from "./Protected/ToProtect";
import { addNotUniqueVisit } from "./actions/statistics/addNotUniqueVisit";
import FooterMobile from "./Footer/FooterMobile";
import Loading from "./Common/loading/Loading";

const QueryAlerts = React.lazy(() =>
  import("./Pages/ForExpert/Query-Alerts/QueryAlerts")
);
const Footer = React.lazy(() => import("./Footer/Footer"));
const Navbar = React.lazy(() => import("./Navbar/Navbar"));

const NotFound = React.lazy(() => import("./Protected/NotFound"));
const ResetPhoneNumber = React.lazy(() =>
  import("./Pages/AutoRegPage/InsidePages/Reset/ResetPhoneNumber")
);
const FieldOfExpertise = React.lazy(() =>
  import("./Pages/HowItWorks/InsidePages/FieldsOfExpertise")
);
const Querists = React.lazy(() => import("./Pages/Querists/Querists.js"));
const LogInAsExpert = React.lazy(() =>
  import("./Pages/AutoRegPage/LogInAsExpertContainer")
);
const MyOffice = React.lazy(() => import("./Pages/MyOffice/MyOffice"));
const editQuery = React.lazy(() => import("./Pages/MyOffice/editQuery"));
const TypeOfQueries = React.lazy(() =>
  import("./Pages/HowItWorks/InsidePages/TypeOfQueries")
);
const InterChat = React.lazy(() => import("./Pages/InterChat/InterChat"));

const LogIn = React.lazy(() => import("./Pages/AutoRegPage/LogIn"));
const SignUp = React.lazy(() => import("./Pages/AutoRegPage/SignUp"));
const Terms = React.lazy(() =>
  import("./Pages/AutoRegPage/TermsPrivacy/Terms")
);
const Privacy = React.lazy(() =>
  import("./Pages/AutoRegPage/TermsPrivacy/Privacy")
);
const TermsOfSubscriptions = React.lazy(() =>
  import("./Pages/AutoRegPage/TermsOfSubscriptions/TermsOfSubscriptions")
);
const HowItWorks = React.lazy(() => import("./Pages/HowItWorks/HowItWorks"));
const MainPage = React.lazy(() => import("./Pages/MainPage/MainPage"));
const ForExpert = React.lazy(() => import("./Pages/ForExpert/ForExpert"));
const ForQuerist = React.lazy(() => import("./Pages/ForQuerist/ForQuerist"));
const ExpertBenefit = React.lazy(() =>
  import("./Pages/ForExpert/InsidePages/BenefitForExp")
);
const QueristBenefit = React.lazy(() =>
  import("./Pages/ForQuerist/InsidePages/BenefitForQuerist")
);
const DBofQueries = React.lazy(() => import("./Pages/DBofQueries/DBofQueries"));
const DBofExperts = React.lazy(() => import("./Pages/DBofExperts/DBofExperts"));
const ResetPassword = React.lazy(() =>
  import("./Pages/AutoRegPage/InsidePages/Reset/ResetPassword")
);
const PostWithdrawQuery = React.lazy(() =>
  import("./Pages/ForQuerist/InsidePages/PostWithdrawQueryContainer")
);
const SignedUp = React.lazy(() =>
  import("./Pages/AutoRegPage/InsidePages/SignedUp/SignedUp")
);
const ContinueReg = React.lazy(() =>
  import("./Pages/AutoRegPage/InsidePages/Continue/ContinueRegistration")
);
const Complete = React.lazy(() =>
  import("./Pages/AutoRegPage/InsidePages/Complete/Complete")
);
const FlowChart = React.lazy(() =>
  import("./Pages/HowItWorks/InsidePages/Flowchart")
);
const BlockDiagram = React.lazy(() =>
  import("./Pages/HowItWorks/InsidePages/BlockDiagram")
);
const LogOut = React.lazy(() =>
  import("./Pages/AutoRegPage/InsidePages/Logout/LogOut")
);
const PressNews = React.lazy(() => import("./Footer/InsidePages/PressAndNews"));
const Language = React.lazy(() => import("./Footer/InsidePages/Language"));
const HelpDesk = React.lazy(() => import("./Footer/InsidePages/HelpDesk"));
const AboutUs = React.lazy(() => import("./Footer/InsidePages/AboutUs"));
const Contacts = React.lazy(() => import("./Footer/InsidePages/Contacts"));
const FAQs = React.lazy(() => import("./Footer/InsidePages/FAQs"));
const Associates = React.lazy(() => import("./Footer/InsidePages/Associates"));
const Endowments = React.lazy(() => import("./Footer/InsidePages/Endowments"));
const Sitemap = React.lazy(() => import("./Footer/InsidePages/SiteMap"));
const UsefulTemplates = React.lazy(() =>
  import("./Footer/InsidePages/UsefulTemplates")
);
const SiteStatistics = React.lazy(() =>
  import("./Footer/InsidePages/SiteStatistics")
);
const MessageToQueristsPage = React.lazy(() =>
  import("./Pages/MessageToQuerists/MessageToQueristPage")
);
const MessageToExpertsPage = React.lazy(() =>
  import("./Pages/MessageToExperts/MessageToExperts")
);
const AcceptQuery = React.lazy(() => import("./Pages/AcceptQuery/AcceptQuery"));
const DBOfQueryAlerts = React.lazy(() =>
  import("./Pages/DBOfQueryAlerts/DBOfQueryAlerts")
);
const QueryUpdate = React.lazy(() =>
  import("./Pages/ForExpert/InsidePages/QueryUpdate/QueryUpdatePage")
);

const App = (props) => {
  useEffect(() => {
    props.chekHTTPS();
    props.addNotUniqueVisit();
    props.checkExp();
    props.checkExpInFac();
    props.checkQuerist();
    props.checkAdmin();

    const interval = setInterval(() => {
      props.checkExp();
    }, 10000000);

    return () => clearInterval(interval);
  }, [props]);

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Navbar />
        <div className={s.container} style={{ flexGrow: 1 }}>
          <div className={s.bodyField}>
            <Switch>
              <Route
                path="/chat/:query_id/:owner_id/:expert_id"
                component={InterChat}
              />
              <Route exact path="/howitworks" component={HowItWorks} />
              <Route
                exact
                path="/howitworks/blockdiagram"
                component={BlockDiagram}
              />
              <ToProtect
                access={props.isAdmin}
                exact
                path="/admin/message-to-querists"
                component={MessageToQueristsPage}
              />
              <ToProtect
                access={props.isAdmin}
                exact
                path="/admin/database-of-query-alerts"
                component={DBOfQueryAlerts}
              />
              <ToProtect
                access={props.isAdmin}
                exact
                path="/admin/message-to-experts"
                component={MessageToExpertsPage}
              />
              <Route exact path="/accept-query" component={AcceptQuery} />
              <Route exact path="/howitworks/flowchart" component={FlowChart} />
              <Route
                exact
                path="/howitworks/fieldsofexpertise"
                component={FieldOfExpertise}
              />
              <Route
                exact
                path="/howitworks/typesofqueries"
                component={TypeOfQueries}
              />
              <ToProtect
                exact
                path="/admin/querists"
                access={props.isAdmin}
                component={Querists}
              />
              <Route exact path="/forquerist" component={ForQuerist} />
              <Route
                exact
                path="/forquerist/benefit"
                component={QueristBenefit}
              />
              <ToProtect
                path="/forquerist/withdraw/"
                access={props.isQuerist}
                component={PostWithdrawQuery}
              />
              <Route exact path="/forquerist/experts" component={DBofExperts} />
              <Route exact path="/forexpert" component={ForExpert} />
              <Route
                exact
                path="/forexpert/benefit"
                component={ExpertBenefit}
              />
              <Route exact path="/forexpert/queries" component={DBofQueries} />
              <ToProtect
                access={props.isExpert}
                exact
                path="/forexpert/query-alerts"
                component={QueryAlerts}
              />
              <ToProtect
                access={props.isExpert}
                exact
                path="/forexpert/update-alerts/:id"
                component={QueryUpdate}
              />
              <Route
                exact
                path="/usefultemplates"
                component={UsefulTemplates}
              />
              <ToProtect
                exact
                access={!props.isQuerist}
                path="/signup"
                component={SignUp}
              />
              <ToProtect
                exact
                access={!!(!props.isExpert && props.isQuerist)}
                path="/signup/continuereg"
                component={ContinueReg}
              />
              <ToProtect
                exact
                access={props.isQuerist}
                path="/signup/signednup"
                component={SignedUp}
              />
              <ToProtect
                exact
                access={props.isExpert}
                path="/signup/complete"
                component={Complete}
              />
              <ToProtect
                exact
                path="/login"
                access={!props.isQuerist}
                component={LogIn}
              />
              <ToProtect
                exact
                path="/myoffice/editquery/:id"
                access={props.isQuerist}
                component={editQuery}
              />
              <ToProtect
                path="/myoffice/"
                access={props.isQuerist}
                component={MyOffice}
              />
              <ToProtect
                exact
                access={props.isQuerist}
                path="/expert_login"
                component={LogInAsExpert}
              />
              <Route
                exact
                path="/login/forgotpassword"
                component={ResetPassword}
              />
              <Route
                exact
                path="/login/forgotphone"
                component={ResetPhoneNumber}
              />
              <Route exact path="/login/logout" component={LogOut} />
              <Route exact path="/pressnews" component={PressNews} />
              <Route exact path="/faqs" component={FAQs} />
              <Route exact path="/language" component={Language} />
              <Route exact path="/helpdesk" component={HelpDesk} />
              <Route exact path="/signup/terms" component={Terms} />
              <Route exact path="/signup/privacy" component={Privacy} />
              <Route
                exact
                path="/subscriptionterms"
                component={TermsOfSubscriptions}
              />
              <Route exact path="/sitemap" component={Sitemap} />
              <Route exact path="/aboutus" component={AboutUs} />
              <Route exact path="/associates" component={Associates} />
              <Route exact path="/endowments" component={Endowments} />
              <Route exact path="/contacts" component={Contacts} />
              <Route exact path="/sitestatistics" component={SiteStatistics} />
              <Route exact path="/uploads/*" />
              <Route exact path="/" component={MainPage} />
              <Route path="*" component={NotFound} />
            </Switch>
          </div>
        </div>
        <footer className={s.container}>
          <Footer />
          <FooterMobile />
        </footer>
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isExpert: state.isExpert,
    isQuerist: state.isQuerist,
    isAdmin: state.isAdmin,
    isExpertInFact: state.isExpertInFact,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      chekHTTPS: chekHTTPS,
      checkExp: chekExpAcc,
      checkExpInFac: checkExpInFac,
      checkQuerist: chekQueryAcc,
      checkAdmin: chekAdminAcc,
      addNotUniqueVisit: addNotUniqueVisit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(App);
