const initChatUsers = [];

export default function (state = initChatUsers, action) {
  switch (action.type) {
    case "SET_USERS":
      return action.payload;
    default:
      return state;
  }
}
