import React from "react";
import { Route } from "react-router-dom";
import Restrict from "./Restrict";

export const ToProtect = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.access) {
          return <Component {...props} />;
        } else {
          return <Restrict />;
        }
      }}
    />
  );
};
