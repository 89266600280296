import React from "react";

import { NavLink } from "react-router-dom";
import s from "./Footer.module.scss";
import fb from "../Common/img/footer/fb.png";
import yt from "../Common/img/footer/yt.png";
import tw from "../Common/img/footer/tw.png";
import ig from "../Common/img/footer/ig.png";
import li from "../Common/img/footer/li.png";
import as from "../Common/img/footer/as.png";
import pm from "../Common/img/footer/pm.png";
import yq from "../Common/img/footer/yq.png";
import adyen from "../Common/img/footer/adyen.png";
import stipe from "../Common/img/footer/stripe.png";
import wepay from "../Common/img/footer/wepay.png";
import paypal from "../Common/img/footer/pp.png";
import bitpay from "../Common/img/footer/bp.png";
import down from "../Common/img/footer/down.png";

const FooterMobile = () => {
  return (
    <div className={s.FooterMobile}>
      <div className={s.contentFooterBlockMob}>
        <input type="checkbox" id="hd-1" className={s.hide} />
        <label htmlFor="hd-1">
          Payment Gateways{" "}
          <img
            src={down}
            width={10}
            height={10}
            loading="lazy"
            alt="Payment Gateway"
          />
        </label>
        <div className={s.resortDiv}>
          <li>
            <img loading="lazy" src={paypal} alt="Paypal" />
          </li>
          <li>
            <img loading="lazy" src={stipe} alt="Stripe" />
            <span> </span>
          </li>
          <li>
            <img loading="lazy" src={adyen} alt="Adyen" />
          </li>
          <li>
            <img loading="lazy" src={bitpay} alt="Bitpay" />
            <span> </span>
          </li>
          <li>
            <img loading="lazy" src={wepay} alt="Wepay" />
          </li>
        </div>

        <input type="checkbox" id="hd-2" className={s.hide} />
        <label htmlFor="hd-2">
          User Support{" "}
          <img
            loading="lazy"
            width={10}
            height={10}
            src={down}
            alt="Icon Down"
          />
        </label>
        <div>
          <li>
            <NavLink to="/pressnews" onClick={() => window.scrollTo(0, 0)}>
              Press & News
            </NavLink>
          </li>
          <li>
            <NavLink to="/faqs" onClick={() => window.scrollTo(0, 0)}>
              FAQs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/usefultemplates"
              onClick={() => window.scrollTo(0, 0)}
            >
              Useful Templates
            </NavLink>
          </li>
          <li>
            <NavLink to="/helpdesk" onClick={() => window.scrollTo(0, 0)}>
              Help Desk
            </NavLink>
          </li>
        </div>

        <input type="checkbox" id="hd-3" className={s.hide} />
        <label htmlFor="hd-3">
          Terms & Guides{" "}
          <img
            loading="lazy"
            width={10}
            height={10}
            src={down}
            alt="Icon Down"
          />
        </label>
        <div>
          <li>
            <NavLink to="/signup/terms" onClick={() => window.scrollTo(0, 0)}>
              Terms of Use
            </NavLink>
          </li>
          <li>
            <NavLink to="/signup/privacy" onClick={() => window.scrollTo(0, 0)}>
              Privacy Policy
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/subscriptionterms"
              onClick={() => window.scrollTo(0, 0)}
            >
              Terms of Subscription
            </NavLink>
          </li>
          <li>
            <NavLink to="/contacts" onClick={() => window.scrollTo(0, 0)}>
              Ads guide
            </NavLink>
          </li>
        </div>

        <input type="checkbox" id="hd-4" className={s.hide} />
        <label htmlFor="hd-4">
          Company{" "}
          <img
            loading="lazy"
            width={10}
            height={10}
            src={down}
            alt="Icon Down"
          />
        </label>
        <div>
          <li>
            <NavLink to="/aboutus" onClick={() => window.scrollTo(0, 0)}>
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/associates" onClick={() => window.scrollTo(0, 0)}>
              Associates
            </NavLink>
          </li>
          <li>
            <NavLink to="/endowments" onClick={() => window.scrollTo(0, 0)}>
              Endowments
            </NavLink>
          </li>
          <li>
            <NavLink to="/contacts" onClick={() => window.scrollTo(0, 0)}>
              Contacts
            </NavLink>
          </li>
        </div>

        <input type="checkbox" id="hd-5" className={s.hide} />
        <label htmlFor="hd-5">
          Follow Us{" "}
          <img
            loading="lazy"
            width={10}
            height={10}
            src={down}
            alt="Icon Down"
          />
        </label>
        <div>
          <li>
            <a href="https://www.facebook.com/you.querist.9" target="_blank">
              <img loading="lazy" src={fb} alt="Facebook" />
            </a>
            <span> </span>
            <a href="https://www.youtube.com/channel/UCdIzpbJ_BfM770QfHys_Ekw">
              <img loading="lazy" src={yt} alt="Youtube" />
            </a>
            <span> </span>
            <a href="https://twitter.com/YouQuerist" target="_blank">
              <img loading="lazy" src={tw} alt="Twitter" />
            </a>
            <span> </span>
            <a href="https://www.instagram.com/youquerist" target="_blank">
              <img loading="lazy" src={ig} alt="Instagram" />
            </a>
            <span> </span>
            <a
              href="https://www.linkedin.com/company/youquerist/"
              target="_blank"
            >
              <img loading="lazy" src={li} alt="" />
            </a>
          </li>
          <li className={s.fullWidthBlock}>Mobile App</li>
          <li>
            <NavLink to="">
              <img loading="lazy" src={as} alt="Icon" />
            </NavLink>
            <span> </span>
            <NavLink loading="lazy" to="">
              <img loading="lazy" src={pm} alt="Icon" />
            </NavLink>
            <span> </span>
            <NavLink to="/sitestatistics" onClick={() => window.scrollTo(0, 0)}>
              <img loading="lazy" src={yq} alt="Icon" />
            </NavLink>
          </li>
          <li>
            <div className={s.ptpStyle}>© YOUQUERIST.PTP</div>
          </li>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
