import { combineReducers } from "redux";
import showQueries from "./user/showQueriesReducer";
import favQ from "./user/getFavoriteQueries";
import showExperts from "./user/showExpertsReducer";
import messageGetter from "./user/notification/messageGetterReducer";
import selectFields from "./selectors/selectorFieldsReducer";
import selectCurrencies from "./selectors/selectorCurrenciesReducer";
import selectUserQueries from "./selectors/selectorUserQueriesReducer";
import isUpdated from "./user/notification/isUpdateReducer";
import createdExpAcc from "./user/createdExpAccReducer";
import isQuerist from "../reducers/isQEA/checkIsQuerist";
import isExpert from "../reducers/isQEA/checkIsExpert";
import isExpertInFact from "../reducers/isQEA/checkIsExpertInFact";
import isAdmin from "../reducers/isQEA/checkIsAdmin";
import getExpertInfo from "./user/getExpertInfo";
import getStatInfo from "./statistics/getStatInfo";
import getQuerists from "./admin/getQuerists";
import myQueries from "./user/office/myQueries";
import userData from "./user/office/userData";
import isDeleted from "./user/office/isDeleted";
import currentQuery from "./user/office/currentQuery";
import msgOffice from "./user/office/msg";
import selectedQ from "./user/office/selectedQueries";
import selectedSubscriptions from "./user/office/selectedSubscriptions";
import expsCount from "./main/countExperts";
import queryInfo from "./user/queryInfo";
import ownerData from "./user/office/ownerData";
import charReducer from "./chat/chat";
import meReducer from "./chat/me";
import socketReducer from "./chat/socket";
import selectedSubscriptionsAdmin from "./user/office/selectedSubscriptionsAdmin";

const rootReducer = combineReducers({
  socketReducer: socketReducer,
  isExpert: isExpert,
  isExpertInFact: isExpertInFact,
  isQuerist: isQuerist,
  isAdmin: isAdmin,
  isUpdated: isUpdated,
  createdExpAcc: createdExpAcc,
  selectFields: selectFields,
  selectCurrencies: selectCurrencies,
  selectUserQueries: selectUserQueries,
  messageGetter: messageGetter,
  showQueries: showQueries,
  showExperts: showExperts,
  getQuerists: getQuerists,
  getExpertInfo: getExpertInfo,
  getStatInfo: getStatInfo,
  myQueries: myQueries,
  isDeleted: isDeleted,
  currentQuery: currentQuery,
  userData: userData,
  ownerData: ownerData,
  msgOffice: msgOffice,
  favQ: favQ,
  selectedQ: selectedQ,
  selectedSubscriptions: selectedSubscriptions,
  selectedSubscriptionsAdmin: selectedSubscriptionsAdmin,
  expsCount: expsCount,
  queryInfo: queryInfo,
  charReducer: charReducer,
  meReducer: meReducer,
});

export default rootReducer;
